@tailwind base;
@tailwind components;
@tailwind utilities;

.plus-list::before {
  content: "+";
  position: absolute;
  left: 0;
  transform: translateY(-1px)
}


.font-var-heading {
    font-variation-settings: 'wght' 800, 'GRAD' 0, 'wdth' 151, 'slnt' 0, 'XTRA' 547, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'opsz' 50;
  }

  .loading {
    position: absolute;
    top: 0;
    z-index: 100;
    width: 1%;
    animation: loadAnimation 2s ease-out forwards;
  }
  
  @keyframes loadAnimation {
    to {
      width: 98%;
    }
  }
  
.font-var-standout {
  font-variation-settings: 'wght' 700, 'GRAD' -200, 'wdth' 151, 'slnt' 0, 'XTRA' 603, 'XOPQ' 27, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'opsz' 50;
}

.font-var-h2 {
  font-variation-settings: 'wght' 700, 'GRAD' 0, 'wdth' 144, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'opsz' 40;
}

.project-cutout {
  clip-path: path("M747 1H50C22.938 1 1 22.938 1 50V459.776C1 486.838 22.938 508.776 50 508.776H595.491C622.553 508.776 644.491 486.838 644.491 459.776V428.852C644.491 400.685 667.324 377.852 695.491 377.852H747C774.062 377.852 796 355.913 796 328.852V50C796 22.938 774.062 1 747 1ZM50 0C22.3858 0 0 22.3857 0 50V459.776C0 487.391 22.3857 509.776 50 509.776H595.491C623.105 509.776 645.491 487.391 645.491 459.776V428.852C645.491 401.237 667.876 378.852 695.491 378.852H747C774.614 378.852 797 356.466 797 328.852V50C797 22.3858 774.614 0 747 0H50Z");
}


.project-item-cutout {
  clip-path: path("M0.875 25.126C0.875 11.3189 12.0679 0.125977 25.875 0.125977H459.099C472.906 0.125977 484.099 11.3189 484.099 25.126V57.5C484.099 71.3071 472.906 82.5 459.099 82.5H424C410.193 82.5 399 93.6929 399 107.5V152C399 165.807 410.193 177 424 177H455.324C469.131 177 480.324 188.193 480.324 202V255.416C480.324 269.223 469.131 280.416 455.324 280.416H25.875C12.0679 280.416 0.875 269.223 0.875 255.416V25.126Z")
}

.test-test:after {
  mix-blend-mode: difference;
}

.giving-back-offset-path {
  width: 10px;
  height: 10px;
  position: absolute;
  color: red;
  top: 0;
  left: 0;
  offset-rotate: 0deg;
  offset-path: path(
    "M12.7991 1.99902C12.7991 1.99902 94.3616 116.13 24.5426 97.28C-45.2764 78.4296 73 208.5 73 208.5"
  );
}

.projects-offset-path {
  width: 10px;
  height: 10px;
  position: absolute;
  color: red;
  top: 0;
  left: 0;
  offset-rotate: 0deg;
  offset-path: path(
    "M0.500137 2.50009C0.500137 2.50009 80.1107 7.11725 130.5 15.0005C190.074 24.3205 144.5 98.5008 215.5 92.5009C286.5 86.5011 343 83.4991 352 122.5"
  );
}

.client-offset-path {
  offset-path: path(
    "M1.5 118.5C1.5 118.5 100.5 70.0598 180.5 118.5C260.5 166.94 250 0.999992 250 0.999992"
  );
  width: 10px;
  height: 10px;
  position: absolute;
  color: #2DDA3F;
  top: 0;
  left: 0;
  offset-rotate: 0deg;
}

@media (max-width: 1288px) {
  .project-cutout {
    clip-path: none;
  }
}

@media (max-width: 1024px) { 


  .projects-offset-path {
    offset-path: path("M1.47611 6.07085C1.47611 6.07085 140.321 -22.1495 130.054 124.932C119.788 272.014 83.8657 291.212 83.8657 291.212")
  }

  .giving-back-offset-path {
    offset-path: path("M58.4578 2.3124C58.4578 2.3124 72.8278 24.8939 20.4807 64.9246C-31.8665 104.955 50.2467 152.17 50.2467 152.17")
  }

  .client-offset-path {
  offset-path: path("M40.1001 90.9475C40.1001 90.9475 103.358 55.0653 103.742 34.9887C104.108 15.805 49.6096 21.4538 49.6096 21.4538C40.0999 24.0141 3.88984 25.5503 3.88984 0.971371");
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  offset-rotate: 0deg;
  }

  .project-item-cutout {
    clip-path: none;
  }

}



.super-xl-visible {
  display: none;
}


@media (min-width: 2600px) {

  .super-xl-visible {
    display: block;
  }

  .super-xl-hidden {
    display: none!important;
  }


}

.check:checked:before {
  content: "✓";
  font-weight: bold;
  position: absolute;
  transform: translateX(5px)
}